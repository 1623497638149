import Images from "~data/imageImports";
const ProcessData = [
      {
        id:"ps1",
        icon: "1",
        iconBackground: "#5034fc",
        title: "Brainstorm with the Customer",
        text:
          "The first step is to sit with our customers to work on how to build a system to help them to transform radically and in a better way the business/organizations ",
      },
      {
        id:"ps2",
        icon: "2",
        iconBackground:"#ef4339",
        title: "Design Process",
        text:
          "Work together with our customers on designing a system with the best User Experience and simple to use",
      },
      {
        id:"ps3",
        icon: "3",
        iconBackground:"#0abfbc",
        title: "Interactive Development",
        text:
          "Involve our customers and collaborators in each phase of the system development and deliver on a continuous basis",
      },
  ]
  export default ProcessData;