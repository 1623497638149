import React from "react";
import { PageWrapper } from "~components/Core"
import HeaderButton from "~sections/home/Header"
import HeroSection from '~sections/home/Hero'
import ServicesSection from '~sections/home/Services'
import ProcessSection from '~sections/home/Process'
import CtaSection from '~sections/home/Cta'
import ContactSection from '~sections/home/Contact'
import FooterSection from '~sections/home/Footer'

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--with-border site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnText="Contat Us"
      btnLink="#"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function LandingPage() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection/>
        <ServicesSection/>
        <ProcessSection/>
        <CtaSection/>
        <ContactSection/>
        <FooterSection/>
    </PageWrapper>
  )
}